.b-liens {
  .b-liens_links {
    a {
      opacity: 0.5;
      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
  .-border {
    border-color: $second!important;
    font-family: $font-aleo;
  }
}
