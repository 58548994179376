.c-header {
  //position: fixed;
  //top: 0;
  //right: 0;
  //left: 0;
  //z-index: 9;
  position: relative;
  height: rem($header_height);
  background-color: $white;
  border-bottom: rem(7px) solid $second;
  z-index: 9;
  width: 100vw;
  a {
    color: inherit;
  }
  .c-header_wrap {
    display: grid;
    grid-template-columns: rem(180px) 1fr;
    width: 100%;
    height: rem($header_height);
  }
  .c-header_content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    grid-gap: rem($unit-small);
  }
  .c-header_logo {
    position: relative;
    display: flex;
    //align-items: center;
    .logo {
      position: absolute;
      width: 100%;
    }
    img {
      //height: rem(180px);
      //width: rem(160px);
    }
  }
  .c-header_sitename {
    font-size: rem(67px);
    font-family: $font-aleo;
    text-transform: uppercase;
    color: $primary;
    font-weight: $light;
    line-height: 1;
  }
  .c-header_sitedesc {
    font-size: rem(19px);
    font-family: $font-aleo;
    text-transform: uppercase;
    color: $primary;
    letter-spacing: 1.2px;
    line-height: 1;
  }
  .c-header_title {
    align-self: center;
  }
  .c-header_nav {
    line-height: 1;
    display: flex;
  }
  .c-header_link {
    color: $black-light;
    margin: 0 0.7rem;
    font-family: $font-aleo;
    font-size: rem($font-size-a);
    padding: 1.5rem 0;
    display: block;
    &:hover, &.is-active {
      color: $black;
    }
  }


  @media (max-width: $to-medium) {
    height: rem($header_height_small);
    .c-header_wrap {
      grid-template-columns: rem(100px) 1fr;
      height: rem($header_height_small);
    }
    .c-header_sitename {
      font-size: 2.5rem;
    }
    .c-header_sitedesc {
      font-size: 0.7rem;
    }
  }
  @media (max-width: $to-tiny) {
    .c-header_sitename {
      font-size: 1.8rem;
    }
  }
}

.c-menu_item {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: rem(5px);
    z-index: 1;
    height : 0;
    width : 0;
    border-right : rem(10px) solid transparent;
    border-bottom : rem(10px) solid $second;
    border-left : rem(10px) solid transparent;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }
  .c-menu_sub-item {
    position: absolute;
    top: 100%;
    background-color: $white;
    display: none;
    grid-gap: 1rem;
    opacity: 0;
    padding: 1.5rem 0;
    z-index: 2;
    box-shadow: rem(7px) rem(7px) rem(10px) #00000029;
    border-radius: 0 0 rem(5px) rem(5px);
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;
    .c-header_link {
      padding: 0 1.5rem;
      margin: 0;
      font-size: rem(16px);
    }
  }
  &:hover {
    .c-menu_sub-item {
      display: grid;
      opacity: 1;
    }
  }
  &:hover, &.is-active {
    &::after {
      opacity: 1;
    }
  }
}

.c-header_nav-icon {
  position: relative;
  width: 40px;
  height: 22px;
  cursor: pointer;
  float: right;
  margin-top: 0.5rem;
}

.c-header_nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $primary;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.c-header_nav-icon span:nth-child(1) {
  top: 0px;
}

.c-header_nav-icon span:nth-child(2) {
  top: 10px;
  width: 75%;
}

.c-header_nav-icon span:nth-child(3) {
  top: 20px;
}

.c-header_nav_burger {
  display: none;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: $primary;
  width: rem(66px);
  height: rem(66px);
}
.c-header_nav_burger {
  &.is-active {
    background-color: $primary;
    color: $white;
    display: flex;
    .c-header_nav-icon {
      margin-top: 0;
    }
    .c-header_nav-icon span {
      background: $white;
    }
    .c-header_nav-icon span:nth-child(1) {
      top: 10px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .c-header_nav-icon span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    .c-header_nav-icon span:nth-child(3) {
      top: 10px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}



.c-header_scroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  transform: translateY(-250px);
  transition: transform $speed $easing;
  &.is-active {
    transform: translateY(0);
  }
}

@media only screen and (max-width: $to-huge) {
  .c-header {
    .c-header_nav {
      display: none;
    }
    .c-header_nav_burger {
      display: flex;
    }
  }
}
