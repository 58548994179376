// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-sans-serif: sans-serif;
$font-serif: serif;
$aleo: 'Aleo';
$opensans: 'Open Sans';
$font-aleo: $aleo, $font-serif;
$font-opensans: $opensans, $font-sans-serif;

// Typography
// =============================================================================
// Base
$font-size:   18px;
$line-height: 30px / $font-size;
$font-family: $font-opensans;
$color:       #222222;
// Headings
$font-size-h1:  63px  !default;
$font-size-h2:  70px  !default;
$font-size-h3:  48px  !default;
$font-size-h4:  44px  !default;
$font-size-h5:  25px  !default;
$font-size-h6:  16px  !default;
$font-size-a:   18px  !default;
$line-height-h: $line-height;
// Weights
$light:  300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold:   700;

// Transitions
// =============================================================================
$speed:  0.3s;
$easing: $Power2EaseOut;

// Spacing Units
// =============================================================================
$unit:       72px;
$unit-small: 36px;
$unit-tiny:  18px;
$unit-tiny2: $unit-tiny / 2;
$unit-large: $unit * 2;

// Container
// ==========================================================================
$container-width: 2000px;
$container-large-width: 1480px;
$container-medium-width: 1200px;
$container-small-width: 991px;
$padding:         $unit;

// Breakpoints
// =============================================================================
$from-tiny:     500px              !default;
$to-tiny:       $from-tiny - 1     !default;
$from-small:    700px              !default;
$to-small:      $from-small - 1    !default;
$from-medium:   1000px             !default;
$to-medium:     $from-medium - 1   !default;
$from-large:    1200px             !default;
$to-large:      $from-large - 1    !default;
$from-big:      1400px             !default;
$to-big:        $from-big - 1      !default;
$from-huge:     1600px             !default;
$to-huge:       $from-huge - 1     !default;
$from-enormous: 1800px             !default;
$to-enormous:   $from-enormous - 1 !default;
$from-gigantic: 2000px             !default;
$to-gigantic:   $from-gigantic - 1 !default;
$from-colossal: 2400px             !default;
$to-colossal:   $from-colossal - 1 !default;

$header_height: 144px !default;
$header_height_small: 90px !default;
