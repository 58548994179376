.b-media-texte {
  position: relative;
  z-index: 2;
  img {
    border-radius: 5px;
  }
  @media (max-width: $to-small) {
    .order-2 {
      margin-top: rem($unit);
    }
  }
}
