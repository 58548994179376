.c-text {
  line-height: $line-height;
  ul {
    li {
      list-style-type: disclosure-closed;
      margin-left: 1.1rem;
      margin-bottom: rem($unit-tiny);
    }
  }
}
.c-text_legende {
  font-size: rem(14px);
  font-style: italic;
}

::marker {
  font-size: rem(12px);
}
