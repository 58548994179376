.b-texte-image-fullsize {
  position: relative;
  background-color: $grey-extra-light;
  overflow: hidden;
  //display: grid;
  .b-texte-image-fullsize_wrap {
    /*position: relative;
    .b-texte-image-fullsize_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }*/
    position: absolute;
    top: 0;
    //left: 33.3%;
    width: 100%;
    height: 100%;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    transform: translate(150px, 300px);
    /*grid-column: 1;
    grid-row: 1;*/
  }
  /*.o-container {
    grid-column: 1;
    grid-row: 1;
    width: 100%;
  }*/
  .o-layout {
    position: relative;
    z-index: 2;
  }
  @media (max-width: $to-medium) {
    .o-layout_item {
      padding-left: rem($unit)!important;
    }
    .o-layout {
      padding-bottom: 0!important;
    }
    .b-texte-image-fullsize_wrap {
      left: 0;
      position: relative;
      transform: none;
      width: 180%;
      left: calc(#{rem(-$unit)} * 2);
    }
  }
}
