.c-slider {
  &.-nav {
    position: relative;
    .c-slider_navigation-prev {
      left: 0!important;
      transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
    .c-slider_navigation-next {
      right: 0!important;
      transform: translateX(50%) translateY(-50%);
    }
    .c-slider_navigation-prev, .c-slider_navigation-next {
      position: absolute;
      top: 50%;
      width: rem(50px);
      height: rem(50px);
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent linear-gradient(88deg, #2273CC 0%, #66A8F0 100%) 0% 0% no-repeat padding-box;
      color: $white;
      border-radius: 50%;
      opacity: 1!important;
      z-index: 10;
      cursor: pointer;
      svg {
        width: rem(10px);
      }
      &.swiper-button-disabled {
        background: $white;
        color: $grey;
        cursor: default;
      }
    }
  }
}

.c-slider_container {
  &.-offset-small {
    margin-right: rem(-$unit-small);
  }
  &.-offset {
    margin-right: rem(-$unit);
  }
}

.swiper-slide {
  height: auto!important;
}

.c-slider_thumbs {
  .swiper-slide {
    cursor: pointer;
  }
}

.c-slider_thumbs {
  height: rem(90px);
  .swiper-slide {
    figure {
      border: 2px solid$grey;
      border-radius: rem(5px);
      overflow: hidden;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
    }
  }
  .swiper-slide-thumb-active {
    figure {
      border-color: $primary-light;
    }
  }
}
