// ==========================================================================
// Generic
// ==========================================================================

html {
    box-sizing: border-box;
}

//
// Add the correct display in IE 10-.
// 1. Add the correct display in IE.
//
template, /* [1] */
[hidden] {
    display: none;
}

*,
:before,
:after {
    box-sizing: inherit;
}

address {
    font-style: inherit;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

b,
strong {
    font-weight: $bold;
}

a {
    text-decoration: none;

    svg {
        pointer-events: none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure {
    margin: 0;
    padding: 0;
}

p {
  margin: rem($unit-tiny) 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

/**
 * 1. Single taps should be dispatched immediately on clickable elements
 */
a, area, button, input, label, select, textarea, [tabindex] {
    -ms-touch-action: manipulation; /* [1] */
    touch-action: manipulation;
}

[hreflang] > abbr[title] {
    text-decoration: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

hr {
    display: block;
    margin: 1em 0;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid #CCCCCC;
}

::-webkit-scrollbar {
  width: .4em;
  height: .4em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid $grey-light;
  border-radius: 5px;
}
