.c-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $speed $easing;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  padding: rem($unit-small);
  .c-popup_bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $primary;
    opacity: 0.89;
  }
  .c-popup_close {
    position: absolute;
    top: 0;
    right: 0;
    width: rem(70px);
    height: rem(70px);
    background-color: $white;
    color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    /*svg {
      width: rem(28px);
    }*/
    .c-popup_close_span {
      position: relative;
      width: 40px;
      height: 22px;
      cursor: pointer;
      float: right;
    }
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $primary;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 10px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      &:nth-child(3) {
        top: 10px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
  img {
    position: relative;
  }
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
}
