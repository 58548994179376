.b-alerte {
  padding: 0.5rem 0;
  position: relative;
  z-index: 9;
  text-align: center;
  //width: 100vw;
  * {
    display: inline-block;
  }
  .icon {
    margin-left: rem($unit-tiny);
  }
  svg {
    width: rem(25px);
  }
  @media (max-width: $to-small) {
    .c-text {
      font-size: rem(10px);
    }
    .o-container {
      padding: 0 1rem;
    }
  }
}
