.c-heading {
    line-height: 1;
    //margin-bottom: rem(30px);
    font-family: $font-aleo;
    font-weight: $normal;

    &.-border {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $black;
      &.-border-primary {
        border-color: $primary;
      }
    }

    &.-h1 {
        font-size: rem($font-size-h1);
        @media (max-width: $to-small) {
          font-size: rem(45px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        font-weight: $light;
        @media (max-width: $to-small) {
          font-size: rem(50px);
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        font-weight: $light;
        @media (max-width: $to-small) {
          font-size: rem(40px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        &.-small {
          font-size: rem(40px);
        }
        @media (max-width: $to-small) {
          font-size: rem(35px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        font-family: $font-opensans;
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        font-family: $font-opensans;
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
