.c-nav {
  position: fixed;
  //top: rem($header_height); // Header height
  top: 0;
  left: 0;
  //right: 0;
  z-index: 10;
  //visibility: hidden;
  transform: translateX(-100%);
  transition: transform 0.3s $easing;
  //height: calc(100vh - #{rem($header_height)}); // 100vh - header height
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .c-nav_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
      opacity: 0;
      transition: $speed $easing;
      transition-delay: 0.2s;
    }
  }
  .c-nav_wrap {
    min-width: rem(400px);
    overflow: auto;
    //width: calc(100vw - #{rem(66px)} - #{rem($unit)});
    width: 100%;
    display: grid;
    grid-template-columns: 1fr rem(66px);
    font-family: $font-aleo;
    .c-nav_content {

      box-shadow: 0px 4px 6px #00000029;
      background-color: $white;
    }
    @media only screen and (max-width: $to-small) {
      min-width: inherit;
      //width: 100%;
      //width: calc(100vw - #{rem(66px)} - #{rem($unit-small)});
    }
  }
  .c-nav_primary {
    padding: rem($unit);
    padding-bottom: 0;
    .c-menu_item {
      padding: 1rem 0;
      &.is-active {
        &::after {
          display: none;
        }
      }
    }
    a {
      color: $black;
    }
  }
  .c-nav_contact {
    padding: rem($unit);
    padding-top: 0;
    border-top: 1px solid $grey;
  }

  .c-nav_item {
    .c-header_link {
      font-size: rem(20px);
      color: $black;
    }
    .c-nav_sub-item {
      display: none;
      grid-gap: 0.5rem;
      a {
        color: $black-light;
      }
    }
    .c-nav_item_main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      svg {
        width: rem(16px);
        transform: rotate(90deg);
        transition: $speed $easing;
      }
    }
    &.is-toggled {
      .c-nav_sub-item {
        display: grid;
      }
      .c-nav_item_main {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .-tel {
    font-size: rem($font-size-h4);
    @media (max-width: $to-medium) {
      font-size: rem(22px);
      svg {
        width: rem(40px);
      }
    }
  }

}

.has-navOpen {
  .c-nav {
    transform: translateY(0);
    .c-nav_bg {
      &::before {
        opacity: 1;
      }
    }
  }
}
