.b-page-bg {
  background: transparent linear-gradient(#092748 0%, #003D7F 10%) 0% 0% no-repeat padding-box;
  //background-color: $primary;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  .b-page-bg_deco {
    /*position: absolute;
    z-index: 1;
    top: 0;
    left: 0;*/
    position: absolute;
    left: rem(-150px);
    top: rem(-200px);
    opacity: 0.2;
    mix-blend-mode: overlay;
    svg {
      width: 50vw;
    }
  }
  @media (max-width: $to-small) {
    .b-page-bg_deco {
      svg {
        width: 100vw;
      }
    }
  }
}
