.u-c-primary {
  color: $primary;
}
.u-c-primary-light {
  color: $primary-light;
}
.u-c-second {
  color: $second;
}
.u-c-white {
  color: $white;
  a {
    color: $white;
  }
  .-border {
    border-color: $white;
  }
}
.u-c-grey {
  color: $grey;
}
.u-c-red {
  color: $red;
}
.u-c-black-light {
  color: $black-light;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-grey-light {
  background-color: $grey-light;
}
.u-bg-white {
  background-color: $white;
}
