.c-footer {
  background-color: $grey-light;
  .c-footer_cta {
    background: transparent linear-gradient(155deg, #003D7F 0%, #00356F 66%, #002653 100%) 0% 0% no-repeat padding-box;
    padding:  1.5rem 0;
    .c-footer_cta-texte {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      grid-gap: 2rem;
      @media (max-width: $to-small) {
        grid-gap: 1rem;
      }
      .icon {
        margin: 0 1.5rem;
        @media (max-width: $to-small) {
          margin-left: 0;
        }
      }
    }
    .c-footer_cta_title {
      font-family: $font-aleo;
      font-size: $font-size-h5;
      @media (max-width: $to-small) {
        font-size: rem(18px);
      }
    }
    .c-footer_cta_tel {
      font-weight: $bold;
    }
  }
  .c-footer_wrap {
    .c-text {
      font-size: rem(16px);
    }
    .c-footer_logo {

    }
    .c-footer_nav {

    }
  }
  .c-footer_bottom {
    border-top: 1px solid $grey;
    a {
      color: $grey;
      font-size: rem($font-size);
    }
    @media (max-width: $to-small) {
      text-align: center;
      .u-text-right {
        text-align: center!important;
        margin-top: rem($unit-tiny);
      }
    }
  }
  .c-footer_pipe {
    margin: 0 1rem;
    color: $grey;
    &:last-of-type {
      display: none;
    }
  }
  .c-footer_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: rem($unit-small);
    @media (max-width: $to-big) {
      grid-template-columns: 1fr;
    }
    @media (max-width: $to-medium) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: $to-small) {
      grid-template-columns: 1fr;
    }
  }
  .c-footer_button {
    display: flex;
    align-items: center;
    background-color: $white;
    color: $grey;
    border: 1px solid $grey-btn-light;
    border-radius: 10px;
    font-family: $font-aleo;
    .c-footer_button-icon {
      padding: 1.5rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 80%;
        width: 1px;
        background-color: $grey;
      }
    }
    .c-footer_button-label {
      padding: 1.5rem;
    }
  }
  .c-footer_copyright {
    color: $grey;
    font-size: rem(14px);
  }

  @media (max-width: $to-small) {
    .c-footer_cta {
      svg {
        width: rem(50px);
      }
      .c-footer_cta_tel {
        font-size: rem(28px);
      }
    }
  }
}
