.b-deces {
  overflow: hidden;
  .b-deces_left {
    background: transparent linear-gradient(158deg, #063F7D 0%, #022752 100%) 0% 0% no-repeat padding-box;
    color: $white;
    padding: rem($unit) rem($unit_large);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .b-deces_deco {
      position: absolute;
      left: rem(-100px);
      width: 60%;
      top: 0;
      mix-blend-mode: overlay;
      opacity: 0.2;
    }
    *:not(.b-deces_deco) {
      z-index: 2;
    }
  }
  .b-deces_right {
    padding: rem($unit);
    background-color: $grey-extra-light;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .b-deces-buttons {
    margin-top: auto;
    a {
      margin-right: $unit;
      &:last-of-type {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: $to-medium) {
    .b-deces_left {
      padding: rem($unit);
    }
    .b-deces-buttons {
      a {
        margin-right: 0;
        //margin-bottom: rem($unit-small);
      }
    }
  }
  @media (max-width: $to-tiny) {
    .b-deces_left, .b-deces_right {
      padding: rem($unit-small);
    }
  }
}
