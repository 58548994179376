// ==========================================================================
// Form
// ==========================================================================
.c-form {

}

.c-form_item {
    position: relative;
    margin-bottom: rem(30px);
    &.-noMargin {
      margin-bottom: 0;
    }
}

// Label
// ==========================================================================
.c-form_label {
    position: absolute;
    display: block;
    color: $black-light;
    top: 1.5rem;
    left: 1.5rem;
    transition: 0.3s $easing;
    pointer-events: none;
}

.c-form_item.-textarea {
  .c-form_label {
    top: 1.5rem;
  }
}

.c-form_item {
  &.is-active {
    label {
      transform: translateY(calc(-100% - 2rem));
    }
  }
}

.c-form_hide {
  display: none;
}


// Input
// ==========================================================================
$input-icon-color: 424242; // No #

.c-form_input {
    padding: 1.5rem;
    border: 1px solid $grey-btn-light;
    border-radius: 5px;
    background-color: white;

    &:hover {
        border-color: darkgray;
    }

    &:focus {
        border-color: dimgray;
    }

    &::placeholder {
        color: gray;
    }
}

// Checkbox
// ==========================================================================
$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;

.c-form_checkboxLabel {
    @extend .c-form_label;

    position: relative;
    display: inline-block;
    margin-right: rem(10px);
    margin-bottom: 0;
    padding-left: ($checkbox + rem(10px));
    cursor: pointer;

    &::before, &::after {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        margin-top: (-$checkbox / 2);
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
    }

    &::before {
        background-color: $white;
        border: 1px solid lightgray;
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(12px);
        background-repeat: no-repeat;
        opacity: 0;
    }

    &:hover {
        &::before {
            border-color: darkgray;
        }
    }

    .c-form_checkbox:focus + & {
        &::before {
            border-color: dimgray;
        }
    }

    .c-form_checkbox:checked + & {
        &::after {
            opacity: 1;
        }
    }
}

.c-form_checkbox {
    position: absolute;
    width: 0;
    opacity: 0;
}

// Radio
// ==========================================================================
$radio-icon-color: $input-icon-color;

.c-form_radioLabel {
    @extend .c-form_checkboxLabel;

    &::before, &::after {
        border-radius: 50%;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23#{$radio-icon-color}%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
        background-size: rem(6px);
    }
}

.c-form_radio {
    @extend .c-form_checkbox;
}

// Select
// =============================================================================
$select-icon: rem(40px);
$select-icon-color: $input-icon-color;

.c-form_select {
    position: relative;
    cursor: pointer;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        width: $select-icon;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23#{$select-icon-color}%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(8px);
        background-repeat: no-repeat;
        content: "";
        pointer-events: none;
    }
}

.c-form_select_input {
    @extend .c-form_input;

    position: relative;
    z-index: 1;
    padding-right: $select-icon;
    cursor: pointer;
}

// Textarea
// =============================================================================
.c-form_textarea {
    @extend .c-form_input;

    min-height: rem(200px);
}

.c-form {
  [type="radio"]:not(:checked), [type="radio"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
}

.error {
  color: $red;
}


.c-form-checkbox {
  [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  [type="checkbox"]+span:not(.lever) {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  [type="checkbox"]+span:not(.lever):before, [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid $grey-btn-light;
    border-radius: 4px;
    margin-top: 3px;
    -webkit-transition: .2s;
    transition: .2s;
  }
  [type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    border: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked+span:not(.lever):before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid $primary;
    border-bottom: 2px solid $primary;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    border-radius: 1px;
  }
}

.c-form_column {
  column-count: 2;
  @media (max-width: $to-small) {
    column-count: 1;
  }
}
