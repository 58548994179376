// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #1A1A1A;
$black-light: #4E4E4E;
$primary: #003D7F;
$primary-light: #1A47B0;
$second: #E3C03E;

$grey: #747474;
$grey-light: #F4F4F4;
$grey-extra-light: #F7F8FB;
$grey-btn: #B7B7B7;
$grey-btn-light: #D3D3D3;

$red: #BD1335;

$black-overlay-light: rgba(0, 0, 0, 0.3);


// Specific
// =============================================================================
// Link
$link-color:       $primary;
$link-focus-color: $primary;
$link-hover-color: $second;
// Selection
$selection-text-color:       $white;
$selection-background-color: $primary;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
