.c-card {
  &.-page {
    border: 1px solid $grey-light;
    .c-card_wrap {
      position: relative;
      height: rem(450px);
      .c-card_image {
        height: 100%;
        overflow: hidden;
      }
      .c-card_content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.5rem;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: $primary;
          opacity: 0.8;
        }
        .c-card_body {
          position: relative;
          z-index: 2;
          .c-card_title {
            display: grid;
            grid-template-columns: 1fr rem(54px);
            grid-gap: 1rem;
            align-items: flex-end;
            justify-content: space-between;
          }
        }
      }
      img {
        transition: $speed $easing;
      }
      @media (max-width: $to-small) {
        height: rem(250px);
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    .c-button {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(54px);
      height: rem(54px);
      line-height: 1;
    }
    @media (max-width: $to-small) {
      .c-heading {
        font-size: rem(25px)!important;
      }
    }
  }
  &.-actualite {
    .c-card_wrap {

    }
    .c-card_image {

    }
    &:hover {
      .c-card_image {
        figure {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  &.-produit {
    a {
      color: inherit;
    }
    .c-card_wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      .c-card_image {
        overflow: hidden;
        border-bottom: 1px solid $grey-light;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          transition: $speed $easing;
          max-height: 100%;
        }
      }
      .c-card_foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
      }
      .c-card_price {
        font-size: rem($font-size-h5);
        color: $primary;
      }
    }
    .c-card_title {
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .c-button {
      box-shadow: none;
      font-size: rem(14px);
      padding: 0.5rem 1rem;
    }
    &:hover {
      .c-card_wrap {
        .c-card_image {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  &.-point-vente {
    position: relative;
    .c-card_content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .c-card_title {
      font-family: $font-aleo;
      font-size: $font-size-h5;
    }
    .c-card_wrap {
      background-color: $white;
      color: $primary;
      box-shadow: 11px 10px 15px #00000046;
      border-radius: 5px;
      overflow: hidden;
      flex: 1;
      .c-card_image {
        height: rem(200px);
        overflow: hidden;
        img {
          transition: $speed $easing;
        }
      }
      .c-card_body {
        padding: rem($unit-small);
        font-family: $font-aleo;
      }
    }
    .c-card_foot {
      position: absolute;
      top: 100%;
      transform: translateY(-50%);
      width: calc(100% - #{rem($unit-small)});
    }
    .c-button {
      font-size: rem(16px);
      padding: 0.5rem 1rem;
      .c-button_icon {
        svg {
          width: rem(8px);
        }
      }
    }
    &:hover {
      .c-card_wrap {
        .c-card_image {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
  &.-avis-deces {
    .c-card_wrap {
      padding: rem($unit-small);
      background-color: $grey-light;
      .c-card_head {
        padding-bottom: rem($unit-tiny);
        border-bottom: 1px solid $primary;
      }
      .c-crad_body {

      }
      .c-card_fp {
        max-height: rem(133px);
      }
      .c-card_actions {
        display: flex;
        align-items: center;
      }
      .c-popup_wrap {
        display: none;
      }
    }
  }
}
