.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  z-index: 12;
  transition: $speed $easing;
}

.is-ready {
  .c-loader {
    transform: translateY(-100%);
  }
}
