.b-galerie {
  .c-figure {
    max-height: rem(650px);
  }
  .c-slider_container {
    box-shadow: rem(30px) rem(30px) rem(60px) #00000080;
    overflow: hidden;
    border-radius: rem(5px);
  }
  .c-slider_thumbs {
    overflow: hidden;
    .c-figure {
      max-height: rem(150px);
    }
  }
}
