.c-button {
    font-weight: $semibold;
    font-size: rem($font-size-a);
    padding: rem(15px) rem(30px);
    background: transparent linear-gradient(88deg, #2273CC 0%, #66A8F0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 60px #00000066;
    border-radius: 5px;
    color: $white;

    .c-button_icon {
      svg {
        width: rem(10px);
        transition: $speed $easing;
      }
      &.-right {
        margin-left: 1.5rem;
      }
      &.-left {
        margin-right: 1.5rem;
        &.-rotate {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &.-white {
      background: $white;
      color: $primary;
    }

    &.-grey {
      background: $grey-btn;
      color: $white;
    }

    &.-small {
      font-size: rem(16px);
    }

    @include u-hocus {
        //background-color: darkgray;
        color: $white;
        &.-white {
          color: $primary;
        }

        .c-button_icon {
          svg {
            transform: translateX(0.5rem);
          }
        }
    }
}

.c-icon-button {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: rem(12px);
  max-width: rem(150px);
  background-color: $white;
  border: 1px solid $grey-btn-light;
  border-radius: 5px;
  padding: rem($unit-tiny);
  color: $grey;
  -webkit-appearance: none;
  .c-icon-button_label {
    padding-top: 0.5rem;
    margin-top: 0.5rem;
    border-top: 1px solid $grey-btn-light;
  }
  svg {
    width: rem(30px);
  }
  &:hover {
    color: $primary;
  }
}
