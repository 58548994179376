.b-paragraphe {
  position: relative;
  .b-paragraphe_image {
    border-radius: rem(5px);
    overflow: hidden;
    img {
      max-height: rem(450px);
    }
  }
  @media (max-width: $to-medium) {
    .-medium {
      &.-margin {
        margin-bottom: rem($unit);
      }
    }
  }
  @media (max-width: $to-small) {
    .-small {
      &.-margin {
        margin-bottom: rem($unit);
      }
    }
  }
}
