.b-single {
  .c-slider_container {
    box-shadow: rem(30px) rem(30px) rem(60px) #00000080;
    overflow: hidden;
    //max-height: rem(600px);
  }
  .c-slider_thumbs {
    overflow: hidden;
  }
  .swiper-slide {
    img {
      border-radius: rem(5px);
    }
  }
  .b-single-contact {
    border-top: 1px solid $white;
    padding: rem($unit-tiny) 0;
    &:first-of-type {
      border-top: none;
    }
  }
  .b-single_map {
    height: rem(550px);
  }
  &.-produit {
    .b-single_images {
      display: grid;
      grid-template-columns: rem(80px) 1fr;
      grid-gap: rem($unit-small);
      align-items: flex-start;
      .b-single_images_thumbnails {
        display: grid;
        grid-gap: 1rem;
        .b-single_images_thumbnail {
          width: rem(80px);
          height: rem(80px);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $grey-light;
          cursor: pointer;
          &.is-active {
            border-color: $grey;
          }
        }
      }
    }
    .b-single_main-image {
      position: relative;
      #large-img {
        background-repeat: no-repeat;
        width: rem(150px);
        height: rem(150px);
        box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
        pointer-events: none;
        position: absolute;
        border: 4px solid #efefef;
        z-index: 99;
        border-radius: 100%;
        display: block;
        opacity: 0;
        transition: opacity 0.2s;
      }
      &:hover,
      &:active {
        #large-img {
          opacity: 1;
        }
      }
    }
    .c-text {
      li {
        margin-bottom: 0.5rem;
      }
    }
  }
  .b-single_title {
    font-size: rem(34px);
    font-weight: $bold;
  }
}
