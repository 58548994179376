.b-carte-permanences {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: 0;
    right: 0;
    background-color: $grey-light;
    z-index: -1;
  }
  .b-carte-permanences_contact {
    a {
      color: $primary;
      display: block;
    }
  }
  .c-text {
    font-family: $font-aleo;
    font-size: $font-size-h5;
  }
  a {
    font-family: $font-aleo;
    font-weight: $bold;
    font-size: rem(21px);
    &.-tel {
      font-size: rem($font-size-h4)
    }
  }
  @media (max-width: $to-medium) {
    .u-padding {
      padding: rem($unit-small)!important;
    }
    a {
      font-size: rem(14px);
      &.-tel {
        font-size: rem(24px);
        padding-left: 0!important;
      }
      svg {
        width: rem(40px);
      }
    }
    .-map {
      order: 2;
    }
    .-content {
      order: 1;
    }
  }
}

.acf-map {
  width: 100%;
  height: 100%;
  min-height: rem(350px);
}
