.b-devis {
  .b-devis_container {
    background-color: $white;
    border-radius: rem(5px);
    overflow: hidden;
  }
  .b-devis_head {
    padding: rem($unit-small) rem($unit);
    background-color: $grey-light;
    border-bottom: 1px solid $second;
    .-h3 {
      font-weight: $normal;
    }
  }
  .b-devis_item {
    padding: rem($unit-tiny) rem($unit);
    border-bottom: 1px solid $grey-light;
    color: $grey;
    display: grid;
    grid-gap: rem($unit);
    align-items: center;
    grid-template-columns: 1fr rem(80px);
    font-family: $font-aleo;
    &.-coche {
      grid-template-columns: 1fr rem(80px) rem(80px);
    }
    .b-devis_item_icon {
      svg {
        max-width: rem(22px);
      }
    }
  }
  .b-devis_foot {
    padding: rem($unit-small) rem($unit);
    color: $primary;
    font-family: $font-aleo;
  }
  @media (max-width: $to-small) {
    .b-devis_item {
      grid-gap: rem($unit-tiny);
      font-size: rem(14px);
      &.-coche {
        grid-template-columns: 1fr rem(50px) rem(20px);
      }
    }
  }
  @media (max-width: $to-small) {
    .b-devis_head {
      text-align: center;
    }
    .u-text-right {
      text-align: center!important;
      margin-top: rem($unit-tiny);
    }
    .b-devis_item, .b-devis_head, .b-devis_foot {
      padding: 1rem;
    }
  }
}
