.b-table {
  .b-table_body {
    background-color: $white;
    overflow: hidden;
    border-radius: rem(5px);
    font-family: $font-aleo;
  }
  .b-table_legende {
    font-style: italic;
  }
  .b-table_content {
    position: relative;
    .b-table_deco {
      position: absolute;
      top: 50%;
      transform: translate(20%, -50%);
      right: 0;
      z-index: -2;
      background-color: $primary;
      pointer-events: none;
      img {
        mix-blend-mode: overlay;
      }
    }
  }
  @media (max-width: $to-medium) {
    .b-table_content {
      .b-table_deco {
        position: relative;
        top: auto;
        transform: none;
        z-index: 1;
      }
    }
  }
}

.table {
  overflow: auto;
  box-shadow: rem(30px) rem(30px) rem(60px) #00000080;
  .tr, .th {
    display: grid;
    //grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-template-columns: repeat(3, 1fr);
    .td {
      padding: rem($unit-small) rem($unit);
      text-align: center;
      border-bottom: 1px solid $grey-light;
      @media (max-width: $to-medium) {
        padding: rem($unit-small);
      }
      @media (max-width: $to-small) {
        padding: rem($unit-tiny);
      }
    }
  }
  .th {
    background-color: $grey-light;
    color: $primary;
    border-bottom: 1px solid $second;
    text-transform: uppercase;
  }
  .tr {
    color: $grey;
  }
}
