// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
  $aleo,
  "fonts/Aleo/Aleo-Regular",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 400;
}
@include font-face(
  $aleo,
  "fonts/Aleo/Aleo-Light",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 300;
}
@include font-face(
  $aleo,
  "fonts/Aleo/Aleo-Bold",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 700;
}

@include font-face(
  $opensans,
  "fonts/Open_Sans/OpenSans-Regular",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 400;
}
@include font-face(
  $opensans,
  "fonts/Open_Sans/OpenSans-Bold",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 700;
}
@include font-face(
  $opensans,
  "fonts/Open_Sans/OpenSans-SemiBold",
  ("eot", "woff2", "woff", "ttf")
) {
  font-style:  normal;
  font-weight: 600;
}
