.b-intro-slider-images {
  position: relative;
  .o-container {
    height: 100%;
    .o-layout {
      height: 100%;
    }
  }
  .-agence {
    .c-text {
      font-size: rem(38px);
      line-height: 1.2;
    }
  }
  .-agence, .-tarifs, .-monument {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .-monument {
    .b-intro-slider-images_monument {
      transform: translate(rem(80px), rem(-100px));
      max-width: 80%;
    }
    .-content {
      position: relative;
      z-index: 2;
    }
    @media (max-width: $to-medium) {
      .b-intro-slider-images_wrap {
        display: none;
      }
      .b-intro-slider-images_monument {
        transform: translate(rem(80px), rem(-150px));
        max-width: none;
        z-index: -1;
      }
    }
  }
  p {
    margin: 0;
  }
  .b-intro-slider-images_wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    grid-gap: rem($unit);
    max-height: 100vh;
    .b-intro-slider-item {
      display: flex;
      flex-direction: column;
      .b-intro-slider-item_image {
        border: rem(10px) solid $white;
        flex: 1;
        box-shadow: 20px 20px 30px #00000029;
        background-color: $white;
      }
    }
  }
  .b-intro-slider-images_scrollto {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: rem(50px);
    height: rem(50px);
    background: transparent linear-gradient(178deg, #2273CC 0%, #66A8F0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 60px #00000066;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      width: rem(10px);
      color: $white;
      line-height: 0;
      &.-light {
        opacity: 0.5;
      }
      svg {
        transform: rotate(90deg);
        transition: $speed $easing;
      }
    }
    &:hover {
      .icon {
        svg {
          transform: rotate(90deg) translateX(0.2rem);
        }
      }
    }
  }
  @media (max-width: $to-medium) {
    .o-layout {
      align-items: flex-start!important;
      /*.o-layout_item:first-of-type {
        padding: rem($unit) 0;
      }*/
    }
    .b-intro-slider-images_wrap {
      grid-template-columns: 1fr;
      padding-left: 1.5rem;
      grid-gap: rem($unit-small);
      .c-heading {
        font-size: rem(14px)
      }
      img {
        max-height: rem(100px);
      }
    }
  }
  @media (max-width: $to-medium) {
    .c-heading {
      &.-h1 {
        font-size: rem(42px);
      }
    }
  }
  @media (max-width: $to-small) {
    .b-intro-slider-images_wrap {
      grid-gap: rem($unit-tiny);
      .b-intro-slider-item_image {
        margin-top: rem(5px)!important;
      }
    }
    .c-heading {
      &.-h1 {
        font-size: rem(35px);
      }
    }
    .c-text {
      font-size: rem(16px)!important;
    }
    .swiper-slide {
      padding: rem($unit-small) 0!important;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: rem($unit)!important;
    padding-right: rem($padding);
    padding-left: rem($padding);
    max-width: rem($container-width + ($padding * 2));
    text-align: left;
    .swiper-pagination-bullet {
      width: rem(7px);
      height: rem(7px);
      border-radius: 50%;
      background-color: $white;
      position: relative;
      margin-right: 1rem!important;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: rem(23px);
          height: rem(23px);
          border: 1px solid $white;
          border-radius: 50%;
        }
      }
    }
  }
  .-margin-negative {
    @media (max-width: $to-small) {
      margin-bottom: -6rem;
    }
  }
}

.swiper-slide {
  position: relative;
  //max-height: calc(100vh - 202px);
  .o-container {
    position: relative;
    z-index: 2;
  }
  .b-intro-slider_image-tarifs {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
