.b-relation-produits {
  //overflow: hidden;
  .b-relation-produits_filtres {
    //overflow: hidden;
    .c-form {
      //justify-content: center;
      .b-relation-produits_filtres_wrap {
        display: block;
        overflow-x: auto;
        //grid-gap: rem($unit-tiny) rem($unit);
        white-space: nowrap;
        padding-bottom: 1rem;
      }
      p {
        display: inline-block;
        margin: 0;
        white-space: nowrap;
        margin-right: rem($unit);
        &:last-of-type {
          margin-right: 0;
        }
      }
      label {
        padding: 0.5rem 1.5rem;
        border: 1px solid $grey-btn;
        color: $grey;
        border-radius: rem(5px);
        display: block;
        cursor: pointer;
        input {

        }
        &.is-active, &:hover {
          box-shadow: rem(9px) rem(9px) rem(14px) #00000038;
          border: 1px solid $primary;
          background-color: $primary;
          color: $white;
        }
      }
      @media (max-width: $to-small) {
        //grid-gap: 1rem;
        p {
          margin-right: rem($unit-tiny);
        }
      }
    }
  }
  .b-relation-produits_categorie {
    img {
      box-shadow: 15px 15px 30px #0000003E;
      border-radius: 5px;
    }
  }
  .b-relation-produits_item {
    height: 100%;
  }
}

.pagination {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  .prev, .next {
    width: rem(50px);
    height: rem(50px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent linear-gradient(88deg, #2273CC 0%, #66A8F0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 20px 20px 60px #00000066;
    border-radius: 50%;
    color: $white;
    &.disabled {
      cursor: default;
      background: $white;
      color: $grey;
    }
    svg {
      width: rem(10px);
    }
  }
}
